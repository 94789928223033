<script setup lang='ts'>
import { userAccountLogin } from '~~/src/pages/login/hooks/accountLogin'
import { pxToRem } from '~~/src/common/theme/naive'

const {
  accountForm,
  accountRules,
  accountLogin,
} = userAccountLogin()
</script>

<template>
  <van-form class="accountForm" validate-trigger="onSubmit" :label-width="`${pxToRem(50)}rem`" @submit="accountLogin">
    <van-cell-group inset>
      <van-field
        v-model="accountForm.loginAccountOrPhone"
        name="loginAccountOrPhone"
        label="账号"
        placeholder="请输入账号/手机号"
        :rules="accountRules.loginAccountOrPhone"
      />
      <van-field
        v-model="accountForm.password"
        type="password"
        name="password"
        autocomplete="on"
        label="密码"
        placeholder="请输入密码"
        :rules="accountRules.password"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        登录
      </van-button>
    </div>
  </van-form>
</template>

<style lang="scss" scoped>
.accountForm {
  :deep(.van-cell) {
    --uno: text-3.5 leading-11;
  }

  :deep(.van-button) {
    --uno: leading-10 text-3.5;
  }
}
</style>
