<script setup lang='ts'>
const props = withDefaults(defineProps<{
  show?: boolean
  authUrl?: string
}>(), {
  show: false,
})
const emits = defineEmits({
  'update:show': (isShow: boolean) => typeof isShow === 'boolean',
  'get': () => true,
})

function updateShowHandle(isShow: boolean) {
  emits('update:show', isShow)
}
onDeactivated(() => {
  updateShowHandle(false)
})
watchEffect(() => {
  if (props.show && !props.authUrl)
    emits('get')
})
</script>

<template>
  <n-modal :show="props.show" @update-show="updateShowHandle">
    <div class="bg-white rounded-md" w-345px p-4>
      <div flex items-center justify-end mb-3>
        <i i-carbon-close class="text-5 cursor-pointer" @click="updateShowHandle(false)" />
      </div>
      <iframe ref="elRef" w-full h-400px :src="authUrl" sandbox="allow-top-navigation allow-scripts" />
      <div text-center>
        微信扫一扫授权登录<br>
        请勿关闭当前弹窗
      </div>
    </div>
  </n-modal>
</template>
