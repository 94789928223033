import { baseRule } from '~~/src/common/rules'
import { useAuth } from '~~/src/store'
import type { AccountForm } from '~~/src/store'

export function userAccountLogin() {
  const authStore = useAuth()
  const accountForm = ref<AccountForm>({
    loginAccountOrPhone: '',
    password: '',
  })

  const accountRules = {
    loginAccountOrPhone: [baseRule()],
    password: [baseRule('请填写密码')],
  }
  function accountLogin(e: AccountForm) {
    authStore.accountLogin(e)
  }

  return {
    accountForm,
    accountRules,
    accountLogin,
  }
}
