<script setup lang='ts'>
import { LOAD_STATUS, homePath } from '@qcwp/common'
import { isWechat } from '@qcwp/utils'
import { LoginType, useLoginPage } from './hooks'
import { DEFAULT_DESCRIPTION, DEFAULT_KEYWORDS } from '~~/src/common/site'
import { useThird } from '~~/src/store'

const { isDesktop } = useDevice()
const thirdStore = useThird()
const {
  currentLoginType,
  selectAssignMedia,
  showPopup,
  authUrl,
  getAuthUrl,
  mediaList,
  selectAssignMediaLoadStatus,
  selectAssignMediaLoadHandle,
} = useLoginPage()
</script>

<template>
  <div py-5 md:py-14>
    <utils-site-head :site-meta="{ title: '登录 - 汽车网评', description: DEFAULT_DESCRIPTION, keywords: DEFAULT_KEYWORDS }" />
    <layout-nav-default-back title="登录" />
    <nuxt-link v-if="!isDesktop" class="block w-35 m-auto mb-4 sm:mb-12 md:hidden logo" :to="homePath()">
      <img src="/logo.png" alt="">
    </nuxt-link>
    <div m-auto rounded-md sm="max-w-100 py-6 shadow-lg" sm:border="gray-200 solid 1">
      <div font-black text-5 p-4 text-center>
        {{ LoginType[currentLoginType] }}
      </div>
      <div>
        <page-login-account-login v-if="currentLoginType === 'account'" />
        <page-login-phone-login v-if="currentLoginType === 'phone'" />
        <page-login-select-media-account
          v-if="currentLoginType === 'select'"
          :is-error="selectAssignMediaLoadStatus === LOAD_STATUS.ERROR"
          :media-list="mediaList"
          @select="selectAssignMedia"
          @reload="() => thirdStore.checkThirdSecret() && selectAssignMediaLoadHandle(thirdStore.checkThirdSecret())"
        />
      </div>
      <page-login-agreement />
      <div flex gap-6 justify-center my-3 sm="my-6">
        <div
          v-show="currentLoginType !== 'account'" title="账号登录"
          @click="currentLoginType = 'account'"
        >
          <p class="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 text-6 cursor-pointer text-info">
            <span i-material-symbols:lock />
          </p>
          <p class="text-3 text-gray-500 mt-1 cursor-pointer">
            账号登录
          </p>
        </div>
        <div
          v-show="currentLoginType !== 'phone'" title="手机登录"
          @click="currentLoginType = 'phone'"
        >
          <p class="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 text-6 cursor-pointer text-primary">
            <span i-ant-design:mobile-filled />
          </p>
          <p class="text-3 text-gray-500 mt-1 cursor-pointer">
            手机登录
          </p>
        </div>
        <client-only>
          <div v-if="!isWechat()" class="text-success" title="微信登录" @click="showPopup = true">
            <p class="w-12 h-12 rounded-full flex items-center justify-center bg-gray-100 text-6 cursor-pointer">
              <span i-bi:wechat />
            </p>
            <p class="text-3 text-gray-500 mt-1 cursor-pointer">
              微信登录
            </p>
          </div>
        </client-only>
        <page-login-wechat-open-auth v-model:show="showPopup" :auth-url="authUrl" @get="(getAuthUrl as any)" />
      </div>
      <div flex items-center justify-end lt-md="justify-center mt-10" text-4 px-5>
        <nuxt-link text-gray-700 to="/login/forget">
          忘记密码
        </nuxt-link>
        <n-divider vertical />
        <nuxt-link text-primary to="/login/register">
          注册账号
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
