import { isClient, isString, isWechat } from '@qcwp/utils'
import { LOAD_STATUS, LoginWayEnum } from '@qcwp/common'
import { useAuth, useThird, useWxAuth } from '~~/src/store'
import { authApi } from '~~/src/server'
import type { CanLoginMedia } from '~~/src/server/modules/auth/type'

export enum LoginType {
  phone = '手机登录',
  account = '账号登录',
  select = '选择登录账号',
}
export function useLoginPage() {
  const route = useRoute()
  const redirect = computed(() => (isString(route.query.redirect) && route.query.redirect))
  const loginType = computed(() => {
    if (isString(route.query.type) && Object.keys(LoginType).includes(route.query.type))
      return route.query.type as keyof typeof LoginType
    return 'phone'
  })
  const currentLoginType = ref<keyof typeof LoginType>(loginType.value)
  const authStore = useAuth()
  const thirdAuthStore = useThird()

  // 保存登录回跳地址
  if (redirect.value)
    authStore.saveLoginPrePage(decodeURIComponent(redirect.value))

  // 微信授权登录
  const showPopup = ref(false)
  const { authUrl, getAuthUrl, isShowMediaList, mediaList, selectAssignMediaLoadHandle, selectAssignMediaLoadStatus } = useWechatOpenAuthLogin()
  // 是否展示可登录的媒体列表
  let stop = watch(isShowMediaList, (nv) => {
    if (nv)
      currentLoginType.value = 'select'
    else
      currentLoginType.value = 'phone'
  })

  onUnmounted(() => {
    stop?.()
    stop = null!
  })

  // 登录选择的媒体
  function selectAssignMedia(index: number, item: CanLoginMedia) {
    // 校验 THIRD_INFO_SECRET 是否存在和过期
    if (!thirdAuthStore.checkThirdSecret()) {
      vantDialog({
        title: '提示',
        message: '授权信息已过期，请重新授权',
      })
      return
    }
    authStore.loginAssignMedia({
      mediaId: item.id,
      thirdInfoSecret: thirdAuthStore.THIRD_INFO_SECRET.data,
    }, isWechat() ? LoginWayEnum.WX_MP : LoginWayEnum.WX_OPEN)
  }

  return {
    redirect,
    loginType,
    currentLoginType,
    authStore,
    selectAssignMedia,
    showPopup,
    authUrl,
    getAuthUrl,

    isShowMediaList,
    selectAssignMediaLoadStatus,
    mediaList,
    selectAssignMediaLoadHandle,
  }
}

export function useWechatOpenAuthLogin() {
  const { isShowMediaList, mediaList, getMedia: selectAssignMediaLoadHandle, selectAssignMediaLoadStatus } = useThirdAuthLogin()
  const { authType, authUrl, getAuthUrl, checkWeChatCode } = useWxAuth() || {}

  const thirdAuthStore = useThird()
  const route = useRoute()
  const router = useRouter()
  const query = computed(() => {
    return route.query
  })
  async function thirdAuthLogin() {
    if (!thirdAuthStore.checkAuth(['code']))
      throw new Error('third auth code is undefined')
    try {
      /**
       * 这里有几种情况
       * 1.第三方已经绑定账号，且只绑定了一个 -> 直接返回TokenResponse在 store/auth 中进行了处理
       * 2.第三方已经绑定账号，绑定了多个 code: 412 -> 在下面进行处理
       * 3.第三方没有绑定账号 -> 在拦截器中进行拦截和提示
       */
      const authCode = thirdAuthStore.THIRD_AUTH_SECRET.data.code
      thirdAuthStore.setAuth()
      const { code, data: responseData } = await checkWeChatCode?.(authCode) || {}
      // 412 存在多个媒体号，需要选择对应的媒体号登录
      if (code === 412 && responseData) {
        // console.log('thirdInfoSecret', responseData)
        useThird().setThirdSecret(responseData as unknown as string)

        vantDialog.close()
        vantToast.clear()
        isShowMediaList.value = true
        mediaList.value = await selectAssignMediaLoadHandle(responseData as unknown as string) || []
      }
    }
    catch (error) {
      console.error('thirdAuthLogin error', error)
      recordError(error)
    }
  }

  if (isClient) {
    // watch whether url queries contains wechat auth code
    let stop = watch(query, () => {
      if (Object.keys(query.value).includes('code') && isString(query.value.code)) {
        const authCode = query.value.code as string
        // save third auth
        thirdAuthStore.setAuth({ code: authCode })
        // clear url queries
        router.replace(route.path)
        thirdAuthLogin()
      }
    }, { immediate: true })
    onUnmounted(() => {
      stop?.()
      stop = null!
    })
  }

  return {
    authType, // 微信登录授权类型
    authUrl,
    getAuthUrl,
    checkWeChatCode,
    thirdAuthLogin,

    isShowMediaList,
    selectAssignMediaLoadStatus,
    mediaList,
    selectAssignMediaLoadHandle,
  }
}

/**
 * 获取媒体列表
 * @returns
 */
export function useThirdAuthLogin() {
  const isShowMediaList = ref(false)
  const selectAssignMediaLoadStatus = ref<LOAD_STATUS>(LOAD_STATUS.INIT)
  const mediaList = ref<CanLoginMedia[]>([])
  /**
  * 获取媒体列表
  * @param thirdInfoSecret 密钥
  */
  async function getMedia(thirdInfoSecret: string) {
    selectAssignMediaLoadStatus.value = LOAD_STATUS.LOADING
    try {
      const res = await authApi.getLoginMedia({ thirdInfoSecret })
      selectAssignMediaLoadStatus.value = LOAD_STATUS.END

      return res.data
    }
    catch (error) {
      console.error('getCode error', error)
      selectAssignMediaLoadStatus.value = LOAD_STATUS.ERROR
      recordError(error)
    }
  }
  return {
    isShowMediaList,
    mediaList,
    getMedia,
    selectAssignMediaLoadStatus,
  }
}
